@import "sass-lib";
@media (min-width: 0px) and (max-width: 767px) {
	.flex-list {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-flow: row wrap;
		flex-wrap:wrap;
		display:block !important;
	}
	.wrapper{ margin: 0 30px;  }
	.home-slide-section{
		.slider-home{
			.slide-banner{
				.caption-text{
					.txt{
					 	width: auto;
					 	h2{ font-size: 18px; line-height: 24px; }
					 	h5{ font-size: 12px; line-height: 18px;  margin-bottom: 5px; }
					}
				}
				.slick-dots{
					left: 10px; right: 10px;
					li{
						padding-right: 25px;
						button{ width: 12px; height: 12px; }
						&.slick-active{
							button{
								&:after{width: 22px; height: 22px;}
							}
						}
					}
				}
			}
		}
	}
	.ttl{ font-size: 18px; }
	.two-box{
		 @extend .flex-list;
		 .lf,.rh{ display: block; width: 100%;  }
		.text{ order: 1; margin-bottom: 20px; }
		.images{ order: 2; padding-left: 0; }
		.rh{ padding-left: 0; }
		.paddleft {
			.img {display: block; margin-bottom: 25px;}
		}
		&.project {
			.images {display: none;}
		}

	}
	.logo-header a img{  }
	.togglemenu{
		margin: 22px 0 0 30px; width: 22px; height: 23px;
		span{
			height:2px;
			&:nth-child(2){ top: 6px; }
			&:nth-child(3){ top: 12px; }
		}

	}
	.slider-address .list{ margin-right: 0; }
	header{
		height: 60px;
		.logo-mobile{ width: 50px; height: 32px; opacity: 0; visibility: hidden; @include transition(.2s all ease-out); }
		.main-head{
			&.open{
				&:before{ height:60px; }
				.logo-mobile{ opacity: 1; visibility: visible;}
			}
			.wrapper{
			 margin: 0 30px;
		 		nav{
		 			ul{
		 				padding: 120px 35px 40px;
		 				li{
		 					a{ line-height: 24px; padding: 8px 0;}
		 				}
		 			}
		 		}
			 	.rh_head{
			 		padding: 12px 0; right: 30px;
			 		.src{
			 			margin-left: 10px;
			 			.ic-src{
			 				width: 17px; height: 17px; background-size: 80%; margin-top: 10px;
			 				&:after{ left:-10px; }
			 			}
			 		}
			 		.lang{
			 			select{ background-size: 30%; background-position: 80% center;  }
			 		}
			 	}
			 	.form-search{
			 		input[type='text']{ font-size: 14px; }
			 		@include placeholder{ font-size: 14px; }
			 	}
			}
		}
		&.fixedheader{
			.logo-mobile{ opacity: 1; visibility: visible; }
		}

	}
	.home{
		&.slide-wave{
			.desc{
				width: auto; left: 30px; right: 30px;
				.logo{
					width: 100px; text-align: center; margin: 0 auto 15px; display: block;
					img{ margin: 0 auto; width: 100%; }
				}
			}
			.list{
				figure{ height:600px; }
			}
		}
		&.slider-direksi-qts{
			.wrapper{ padding: 0 30px; }
			.slider-direksi{
				.list{
					text-align: center; display: inline-block;
					@include afterclear;
					&:before{ display:none; }
					figure{
						display: inline-block;
					}
					.desc{ display: inline-block; vertical-align: top; }
				}

			}
		}
		&.newsevent{
			 padding-top:30px;
			.ttl{ font-size: 16px; line-height: 24px; }

		}

		&.form-newsevent{
			.wrapper{ padding: 0 30px; }
			&.scrollify {height: auto;}
			h3{ font-size: 18px; line-height: 26px; letter-spacing: 2px; }
		}
	}
	.wrap-dot-bg{
		.dot-slider{
			.dots-project{
				span{
					&:after{ width:10px; height: 10px; }
				}
				&.slick-center{
					span{ font-size:14px;  }
				}
			}
		}
	}
	.inner-content{ padding: 40px 0 70px; }
	.middle{ padding-top: 60px; }
	.date{ display: none; }
	.three-box{
		h4{ font-size: 14px; max-height: unset;}
		p{ display: none; }
		figure{
			height: 120px;
		}
		&.newshome{
			.list{
				&:nth-child(3){display: none; }
			}
		}
		.list{
			width: 48%; margin-bottom: 40px;
			&:nth-child(3n){ margin-right: 2%; }
			&:nth-child(2n){ margin-right: 0; clear: both; }
		}
		.right{ float: none; }
		.more{   text-align: left; }

	}
	.masterplan{
		padding: 40px 0 ;
		.center{
			h2{ margin-bottom: 20px; }
		}
	}
	.tabbing-spek{
		.btn-tab{ position: relative; width: auto; }
		.box-desc-type{
			float: none; display: block; width: 100%;   order: 2;
			margin-top: 40px;
		}
		.wrap-left-right{ display: flex; flex-direction: column; }
		.content-tab-type{  }
		.slide-type{
			width: 100%; float: none; margin-top: 0; order: 1;
			.border-bg{ height: 280px; }

		}
		.ls-tab{
			a{ font-size: 12px; padding: 10px 15px; }
		}
		.slider-type{
			.list{
				span{ font-size: 12px; padding: 10px 5px;}
			}
			.slick-arrow{
				width: 28px; height: 28px;
				&:before{ width:18px; height: 18px; }
				&:after{  background-size: 100%; margin: auto; width: 8px;}
			}
		}
		.slider-big{
			.slick-arrow{
				width: 32px; height: 32px;
				&:before{ width:22px; height: 22px; }
				&:after{ background-size: 100%; margin: auto; width:10px; }
 			}
		}


	}
	.video-middle{
		.thumb-video{ height: auto; }
	}
	.middle{
		h2{ font-size: 18px; font-weight: bold; line-height: 24px; }
	}
	.end-text-btn{
		padding-top: 25px;
		.right{
			a{ width: 24px; height: 24px; margin: 0 2px; }
			span{ font-size: 12px; padding-top: 5px; margin-right: 5px; line-height: 22px; }
		}
		.left{ padding-top: 5px; }
	}
	.line{ padding-left: 10px; }
	.paddleft{ padding-left: 0; }
	.form-middle{
		.row{ margin-bottom: 0; }
		.col{
			margin-bottom: 15px;
			&.half{ width: 100%; float: none; display: block; margin-right: 0; }
			&.act{
				label{ top: -10px; }
			}
		}
		input[type='text'],
		input[type='email'],
		input[type='number'],textarea,select{ font-size: 12px; height: 40px; padding: 15px 5px 5px 5px;}
		&.white{
			margin:40px 0 ;
		}
	}
	.contact-landing{
		.bg-grey{
			&:before{ height:120px; top: -42px; }
		}
	}
	.row-tittle{
		.left{ width:185px;  }
	}
	.pagging{
		a{ font-size: 12px; }
		.arr{
			width: 32px; height: 32px;
			&:after{ width:5px; background-size: 100%; }
			&:before{ width:22px; height: 22px; }
		}
	}
	.inner-content{
		&.contactus{ padding: 40px 0 0; }
	}
	.stay-wa{
		right: 30px; bottom: 20px;
		a{
			margin-left: 5px; width: 30px; height: 30px;background-size: 50% !important;
		}
	}
	.page100vh{
		h1{ font-size: 120px; }
		h2{ font-size: 38px; line-height: 44px; }
		h3{ font-size: 28px; line-height: 34px; margin-bottom: 10px; }
		h5{ font-size: 16px; line-height: 24px; }
		p{ font-size: 12px; line-height: 20px; }
		&.pagety{
			.text{ margin:0; }
		}
	}
	.slider-gallery{
		figure{
			height: 250px;
			img{ height: 100%; object-fit: cover; }
		}
		.slick-arrow{
			width: 32px; height: 32px; left: 0;
			&:before{ width:24px; height:24px; }
			&:after{ background-size:100%; width: 10px;  margin: auto; }
			&.slick-next{
				right: 0;
			}
		}
	}

	.sec-box-two{
		.wrap-point-box{
			.slick-arrow{
				width: 32px; height: 32px;
				&:before{ width:24px; height: 24px; }
				&:after{ background-size: 100%; width: 10px; margin: auto; }
			}
		}
		.left-box,.right-box{
			&:before{ left:-30px; right: -30px; }
		}
	}
	.detail-page{
		&:before{ height:160px; }
		.img-detail{ height: 200px; margin-bottom: 30px; }
	}
	.slider-address{
		padding: 0 60px;
		.slick-arrow{
			width: 32px; height: 32px;
			&:before{ width:22px; height: 22px; }
			&:after{ background-size: 100%; width: 10px; margin: auto; }
		}
	}
	.sec-gallery{
		.slider-gallery{
			.list{
				&:hover{
					.hide{ height:auto; }
				}
			}
			.hide{ height: auto; }
		}
	}
	.contactus-project{
		h3{ font-size: 20px; }
		.box-form-project{
			iframe{ height: 250px; }
		}
	}
	.pagedown{
		span{ width: 30px; background-size: 100%; text-align: center; margin: auto; }
	}
	.wrap-dot-bg{
		.dot-slider{
			.slick-list{ padding: 0 !important; }
			.dots-project{
				height: 80px;
				span{ color: #fff; line-height: 16px;}
				&:nth-child(5n+1),
				&:nth-child(5n+2),
				&:nth-child(5n+3),
				&:nth-child(5n+4),
				&:nth-child(5n+5){
					span{
						padding: 0 0 20px 0;
					}
				}
				&.slick-center{
					span{ font-size: 12px; }
				}

			}
		}

	}

	footer{
		.top{
			display: flex; flex-direction: column;
			p{ font-size: 11px; line-height: 15px; }
			.box{
				width: 48% !important; margin-right: 4%;
				word-break: break-all;
				&:nth-child(1){  order: 1;}
				&:nth-child(2){  order: 3; }
				&:nth-child(3){ order: 2; margin-right: 0; }
				&:nth-child(4){ margin-right: 0; order: 4;}
			}
		}
	}
	.wrap-pop{
		.pop-inner{
			.content-pop{ width: 400px; margin:30% auto;   }
			figure{
				height: 200px;
			}
			.close{
				width: 25px; height: 25px; right: -5px; top: -10px;
				&:before,&:after{ width:15px; height: 2px; top: 12px; }
			}

		}
		&.zoomin{
			.pop-inner{
				.content-pop{ width: auto; margin: 30% 30px; padding: 15px;}
			}
		}
	}
	.zoom{
		width: 25px; height: 25px; background-size: 50%; opacity: .4;
		&.in{ bottom:42px; }
		&.out{ background-size: 50%; bottom: 15px; }
	}
	.search-result-landing{
	   .box-result{ min-height: 200px; }
	}
}

@media (min-width: 0px) and (max-width: 479px) {

	.home{
		&.slider-direksi-qts{
			.slider-direksi{
				.list{
					text-align: center; margin: 0 auto; display: inline-block;
					@include afterclear;
					&:before{ display:none; }
				}
				figure{
					text-align: center; float: none; margin-left: 0; margin: 0 auto 20px;
					width: 100%; display: inline-block;
					img{ margin: 0 auto; display:block; }
				}
				.desc{ display: inline-block; float: none; }
			}
		}
		&.benefit .box-circle {
			.slick-arrow{
				width: 32px; height: 32px;
				&:before{ width:24px; height: 24px; }
				&:after{ background-size: 100%; width: 10px; margin: auto; }
			}
		}
	}
	.detail-page{
		&:before{ }
		.img-detail{ height: 150px;}
		.wrapper{
			.wrapper{ margin: 0 15px; }
		}
	}
	header .main-head .wrapper .rh_head{ right: 20px; }
	.togglemenu{ margin-left: 20px; }
	.ttl{ font-size: 16px; line-height: 24px; }
	.wrap-pop{
		.pop-inner{
			.content-pop{ width: auto; margin:25% 25px;   }
			figure{
				height: 200px;
			}
		}
	}
}



@media (min-width: 0px) and (max-width: 320px) {
	.three-box{
		figure{ height: auto; }
		&.newshome{
			figure{ height: auto;}
			.list{ margin-bottom: 20px;}
		}
		.list{
			width: 100%; margin-right: 0px;
		}
	}
	.row-tittle .left{ width: 150px; }
	.sec-gallery{
		.slider-gallery{
			padding: 0 20px;
			.slick-arrow{
				width: 22px; height: 22px; right: -10px;
				&:before{ width:18px; height: 18px; }
				&:after{ width: 6px; }
				&.slick-prev{right: auto; left: -10px;}
			}
		}
	}
	.pagging{
		a{ margin: 0 5px;}
		.arr{
			margin-right: 10px;
			&.next{margin-left: 10px;}
		}
	}
}